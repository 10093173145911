import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import HubspotForm from "react-hubspot-form"
import Helmet from "react-helmet"

const Rewardscatalog = ({ location }) => {
  return (
    <Layout location={location}>
        
        <Helmet>
          <title>Global Rewards Catalog | Vantage Circle
          </title>

          <meta
            name="description"
            content="Explore Vantage Circle's Global Rewards Catalog, offering employees a seamless redemption experience with gift cards, merchandise, and experiences across 100+ countries."
          />
          <meta property="twitter:title" content="Global Rewards Catalog | Vantage Circle" />
          <meta name="robots" content="index" />

          <meta
            property="twitter:description"
            content="Explore Vantage Circle's Global Rewards Catalog, offering employees a seamless redemption experience with gift cards, merchandise, and experiences across 100+ countries."
          />

          <meta property="og:type" content="website" />

          <meta
            property="og:url"
            content="https://www.vantagecircle.com/global-rewards-catalog/"
          />

          <meta property="og:site_name" content="Vantage Circle" />
          <meta property="og:title" content="Global Rewards Catalog | Vantage Circle" />

          <meta
            property="og:description"
            content="Explore Vantage Circle's Global Rewards Catalog, offering employees a seamless redemption experience with gift cards, merchandise, and experiences across 100+ countries."
          />
          <link
            rel="canonical"
            href="https://www.vantagecircle.com/global-rewards-catalog/"
          ></link>
        </Helmet>

    <section id='watchVideoClass' class="w-11/12 2xl:max-w-7xl mx-auto mb-5 rounded-xl flex place-content-center relative bg-purple-30 overflow-x-hidden pt-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0 mb-10">
                <div className="w-full grid lg:grid-cols-2 justify-center items-start z-10 lg:px-10 relative">
                    <div className="w-full md:w-11/12 lg:w-full mx-auto flex flex-col text-center lg:text-left justify-center lg:items-start items-center xl:mr-3 xl:pt-20 xl:pb-20">
                            <div className="my-2 flex justify-center items-center">
                                <h2 className="pl-1 text-center lg:text-left heroTagStyle">GLOBAL REWARDS CATALOG</h2>
                            </div>
                            {/* <h1 class="text-newOrange lg:pr-3 xl:w-full text-3xl md:text-4xl xl:text-6xl text-center lg:text-left relative"></h1> */}
                            <h1 className = "lg:pr-3 xl:w-full text-3xl md:text-4xl xl:text-6xl text-center lg:text-left text-indigo-100">Something for <br/> everyone</h1>
                            <p className="my-8 text-indigo-100 text-center lg:text-left w-full" style= {{ lineHeight: '2rem' }}>Give your employees a hassle-free rewards redemption experience with our Global Rewards Catalog available in 100+ countries, 16+ global languages & multiple currencies.</p>
                            <div className="xl:grid grid-cols-2 justify-center items-center lg:justify-start w-5/6 md:w-1/2 lg:w-10/12 2xl:w-11/12 cursor-pointer my-8 z-10">
                                <a className="vc-new-orange-btn-rounded-full text-white lato rounded-full mb-3 xl:mb-0 xl:mr-2 w-full" href="#form">Book a product demo</a>
                                <button className="youtube-video-btn vc-new-indigo-border-btn-rounded-full text-indigo-500 lato rounded-full mt-3 xl:mt-0 xl:ml-2 modal-open modal-toggle w-full">Watch video</button>
                            </div>      
                    </div>
                    {/* <div className="lg:w-5/12 md:h-96 xl:h-80"></div> */}
                    <div className="lg:absolute md:-bottom-64 md:scale-90 lg:scale-75 lg:transform xl:scale-100 md:right-5 lg:-bottom-12 lg:-right-10 xl:-bottom-16 xl:right-5 2xl:right-3 flex justify-center items-center lg:h-full mt-6 pt-4 xl:mt-0 lg:ml-3">
                        {/* <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/07/Rewards-Hero.webp" alt="Vantage Rewards" width="600" /> */}
                        <picture>
                            <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_900/q_auto/gatsbycms/uploads/2023/10/Rewards-cat-hero.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="600" />
                            <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/Rewards-cat-hero.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2023/10/Rewards-cat-hero.webp, https://res.cloudinary.com/vantagecircle/image/upload/w_300/q_auto/gatsbycms/uploads/2023/10/Rewards-cat-hero.webp" alt="Vantage Rewards" width="500" />
                        </picture>
                    </div>
                </div>
        </section>
        <div class="popup-overlay modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
                <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                <div class="popup-content modal-container bg-white w-11/12 md:max-w-md lg:max-w-6xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
                    <button class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
                        <svg class="fill-current text-white buttonClose" width="30" height="30" viewBox="0 0 18 18">
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                        </svg>
                    </button>
                    <div class="modal-content text-left">
                        <div class="aspect-w-16 aspect-h-9">                                
                            <iframe class="video"
                                width="560"
                                height="315"
                                src=""
                                data-src="https://www.youtube.com/embed/cHw__YTanvs"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                title="Vantage Circle"
                                ></iframe>
                        </div>
                    </div>   
                </div>
            </div>
            <section className='max-w-7xl mx-auto py-10 xl:pt-16 xl:pb-0'>
                <div className='w-10/12 md:w-11/12 md:px-3 lg:px-0 lg:w-11/12 xl:w-full mx-auto xl:mx-0'>
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-full mb-0 lg:my-8 relative">
                        <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start lg:justify-start z-10">
                            <img loading='lazy' src='https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2023/10/amazon-business-logo.png' width="214" height="46" className='my-2' />
                           <h2 class="text-gray-250 homepage-heading text-center lg:text-left mb-3 w-full">Supercharge your rewards program with our Amazon Store</h2>
                           <p class="text-gray-250 text-center lg:text-left my-3 xl:my-2">Recognize accomplishments of difference-makers and empower others to follow in their footsteps.</p>
                           <a href='#' className="vc-new-indigo-border-btn-rounded-full text-indigo-500 lato rounded-full mt-3 xl:mt-5 xl:ml-2 modal-open modal-toggle w-10/12 md:w-5/12 text-gray-250">Know more</a>
                       </div>
                       <div class="w-full flex justify-center items-start z-10">
                           <picture>
                                <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2023/10/Rewards-cat-amazon.webp" media="(min-width: 640px)" type="image/webp" alt="vantage-rewards-social-recognition" width="500" />
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/Rewards-cat-amazon.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2023/10/Rewards-cat-amazon.webp" alt="vantage-rewards-social-recognition" width="400" />
                            </picture>
                       </div>
                   </div>
                </div>
            </section>
            <section className='py-10 xl:py-16'>
                <div className='grid lg:max-w-5xl xl:max-w-7xl mx-auto xl:grid-cols-2 gap-10 place-content-center px-3 md:px-5 lg:px-8 xl:px-0 '>
                    <div className='rounded-xl grid md:flex overflow-hidden xl:grid items-end lg:pt-2 xl:pt-0 shadow-xl' style={{ backgroundColor: '#ff6998' }}>
                            <div className=''>
                                <h2 className='lato text-3xl pt-10 xl:pt-7 pb-3 text-white pl-10'>Gift Card</h2>
                                <p className='pb-16 text-white px-10'>Let your employees handpick their preferred gift cards from an extensive collection of both local and global brands, using their reward points.</p>
                            </div>
                            <div className='flex items-end justify-end'>
                                <picture>
                                    <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2023/10/VC-rewards-catalog-gifts-2.webp" media="(min-width: 640px)" type="image/webp" alt="vantage-rewards-social-recognition" width="750" />
                                    <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/VC-rewards-catalog-gifts-2.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2023/10/VC-rewards-catalog-gifts-2.webp" alt="vantage-rewards-social-recognition" width="400" />
                                </picture>
                            </div>
                    </div>
                    <div className='grid grid-cols-1 gap-10'>
                        <div className='md:flex rounded-xl overflow-hidden shadow-xl' style={{ backgroundColor: '#a79eff' }}>
                            <div className=''>
                                <h2 className='lato text-3xl pt-10 pb-5 text-white pl-10'>Experiences</h2>
                                <p className='pb-3 xl:pb-10 text-white px-10'>Give your employees a whole new redemption option and live a perfect work-life balance with relaxing stays, budget-friendly excursions, and much more.</p>
                            </div>
                            <div className='lg:flex items-end justify-end'>
                                <picture>
                                    <source className='transform xl:scale-125 xl:mb-5 mr-28' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_650/q_auto/gatsbycms/uploads/2023/10/VC-rewards-catalog-experience-2.webp" media="(min-width: 640px)" type="image/webp" alt="vantage-rewards-social-recognition" width="750" />
                                    <img className='transform xl:scale-125 xl:mb-5 mr-28' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/VC-rewards-catalog-experience-2.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2023/10/VC-rewards-catalog-experience-2.webp" alt="vantage-rewards-social-recognition" width="400" />
                                </picture>
                            </div>
                        </div>
                        <div className='md:flex rounded-xl overflow-hidden shadow-xl' style={{ backgroundColor: '#fe8934' }}>
                            <div className=''>
                                <h2 className='lato text-3xl pt-10 pb-5 text-white pl-10'>Merchandise</h2>
                                <p className='pb-3 xl:pb-10 text-white px-10'>Offer your employees the flexibility to cash in their reward points for merchandise of their choice across top global brands & get them delivered to their doorstep in no time.</p>
                            </div>
                            <div className='lg:flex items-end justify-end'>
                                <picture>
                                    <source className='transform scale-150 -translate-x-10 translate-y-2 xl:mb-10 mr-40' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_650/q_auto/gatsbycms/uploads/2023/10/Rewards-Catalog-Merchandise.webp" media="(min-width: 640px)" type="image/webp" alt="vantage-rewards-social-recognition" width="1000" />
                                    <img className='transform scale-125 xl:scale-150 translate-x-4 xl:-translate-x-10 translate-y-2 xl:mb-10 mr-40' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/Rewards-Catalog-Merchandise.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_500/q_auto/gatsbycms/uploads/2023/10/Rewards-Catalog-Merchandise.webp" alt="vantage-rewards-social-recognition" width="300" />
                                </picture>
                            </div>
                        </div>
                    </div>
                    {/* <div className='rounded-xl shadow-xl rewardsCatalogGiftsBG'>xccx</div>
                    <div className='grid grid-cols-1 gap-5'>
                        <div className='rewardsCatalogueExperiencedesc rewardsCatalogueExperiencehead relative'>
                            <picture>
                                <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2023/10/VC-rewards-catalog-experience.webp" media="(min-width: 640px)" type="image/webp" alt="vantage-rewards-social-recognition" width="630" />
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/VC-rewards-catalog-experience.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2023/10/VC-rewards-catalog-experience.webp" alt="vantage-rewards-social-recognition" width="400" />
                            </picture>
                        </div>
                        <div className='rewardsCatalogueMerchandisedesc rewardsCatalogueMerchandisehead relative'>
                            <picture>
                                <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2023/10/VC-rewards-catalog-merchandise.webp" media="(min-width: 640px)" type="image/webp" alt="vantage-rewards-social-recognition" width="630" />
                                <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/VC-rewards-catalog-merchandise.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2023/10/VC-rewards-catalog-merchandise.webp" alt="vantage-rewards-social-recognition" width="400" />
                            </picture>
                        </div>
                    </div> */}
                </div>
                <div className='w-4/6 mx-auto lg:w-full flex place-content-center mt-16'>
                    <a href='#' className='vc-new-orange-btn-rounded-full w-5/6 md:w-1/2 lg:w-2/6 xl:w-1/6 text-purple-100'>Book a product demo</a>
                </div>
            </section>

            <section className='w-full flex place-content-center bgWave'>
                <div className='w-11/12 2xl:max-w-7xl mx-auto xl:flex md:px-3'>
                    <div className='card-1 w-full mb-10 md:mb-0 mt-5 xl:mt-0 xl:w-1/2 py-10 xl:py-16'>
                        <h2 className='text-2xl md:text-4xl xl:text-5xl text-center xl:text-left text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 2xl:w-11/12'>
                            <span className='text-orange'>Upgrade</span> your rewards catalog for maximum impact.
                        </h2>
                        <ul className='list-inside text-gray-250 lato text-lg xl:text-xl md:text-md 2xl:text-lg md:px-12 xl:px-0 w-full mt-3'>
                        

                            <li class="flex w-full mt-5 pt-3 pb-1 ml-1">
                                <div class="w-1/12">
                                    <img class='mt-2 pr-2 mx-auto xl:mx-0 md:scale-110 lg:scale-100 md:pr-2 lg:pr-0' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" width="20" height="20" />
                                </div>
                                <div class="w-11/12">
                                    <p class=" m-0 mb-1 text-xl text-gray-500">Create custom rewards catalog</p>
                                    <p className='text-md mt-2'>Choose the right kind of rewards for your employees from our vast selection and make a custom rewards catalog for your organization.</p>
                                </div>
                            </li>
                            <li class="flex w-full mt-5 pt-3 pb-1 ml-1">
                                <div class="w-1/12">
                                    <img class='mt-2 pr-2 mx-auto xl:mx-0 md:scale-110 lg:scale-100 md:pr-2 lg:pr-0' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" width="20" height="20" />
                                </div>
                                <div class="w-11/12">
                                    <p class=" m-0 mb-1 text-xl text-gray-500">We deliver everywhere</p>
                                    <p className='text-md mt-2'>With global delivery partners, we make sure all your employees are covered under your rewards program.</p>
                                </div>
                            </li>
                            <li class="flex w-full mt-5 pt-3 pb-1 ml-1">
                                <div class="w-1/12">
                                    <img class='mt-2 pr-2 mx-auto xl:mx-0 md:scale-110 lg:scale-100 md:pr-2 lg:pr-0' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" width="20" height="20" />
                                </div>
                                <div class="w-11/12">
                                    <p class=" m-0 mb-1 text-xl text-gray-500">Make service anniversaries memorable</p>
                                    <p className='text-md mt-2'>Create a custom milestone catalog to reward your tenured employees with our Amazon Store or Merchandise.</p>
                                </div>
                            </li>
                            <li class="flex w-full mt-5 pt-3 pb-1 ml-1">
                                <div class="w-1/12">
                                    <img class='mt-2 pr-2 mx-auto xl:mx-0 md:scale-110 lg:scale-100 md:pr-2 lg:pr-0' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1.webp" width="20" height="20" />
                                </div>
                                <div class="w-11/12">
                                    <p class=" m-0 mb-1 text-xl text-gray-500">Spend points to shop</p>
                                    <p className='text-md mt-2'>Your employee can spend points to buy gift cards, merchandise, amazon products and experiences right from the Vantage Circle platform.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='xl:w-1/2'>

                        {/* <picture>
                            <source loading="lazy" className='flex lg:hidden w-full' srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2023/10/VC-rewards-catalog-upgrade-rewards-1.webp" media="(min-width: 640px)" type="image/webp" alt="vantage-rewards-social-recognition" width="630" />
                             <img loading="lazy" className='flex lg:hidden w-full' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/VC-rewards-catalog-upgrade-rewards-1.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2023/10/VC-rewards-catalog-upgrade-rewards-1.webp" alt="vantage-rewards-social-recognition" width="400" />
                        </picture>                     */}
                    </div>
                </div>
            </section>
            <section className='w-full pt-10 xl:pt-16 flex place-content-center overflow-hidden lg:px-5 xl:px-0 bg-indigo-100'>
                <div className='lg:w-full xl:w-10/12 2xl:w-8/12 mx-auto'>
                    <h2 className='homepage-heading text-purple-100 text-center'>Our global capabilities</h2>
                    <div className='relative mx-auto'>
                        <div className='grid grid-cols-2 lg:grid-cols-4 place-content-center gap-5 z-10 relative py-8 lg:py-16'>
                            <div className='grid gap-3'>
                                <div className='rounded-lg shadow-xl border-orange py-5 px-6 bgFrostIndigo'>
                                    <h2 className='text-purple-100 homepage-heading'>1K+</h2>
                                    <p className='text-purple-100 m-0'>Gift cards</p>
                                </div>
                                <div className='rounded-lg shadow-xl border-orange py-5 px-6 bgFrostIndigo'>
                                    <h2 className='text-purple-100 homepage-heading'>100+</h2>
                                    <p className='text-purple-100 m-0'>Experiences</p>
                                </div>
                                <div className='rounded-lg shadow-xl border-orange py-5 px-6 bgFrostIndigo'>
                                    <h2 className='text-purple-100 homepage-heading'>1K+</h2>
                                    <p className='text-purple-100 m-0'>Merchandise</p>
                                </div>
                            </div>
                            <div className='h-full'>
                                <div className='rounded-xl shadow-xl border-orange p-3 grid place-content-center h-full bgFrostIndigo'>
                                    <h2 className='text-purple-100 homepage-heading'>16+</h2>
                                    <p className='text-purple-100'>Languages</p>
                                </div>
                            </div>
                             <div className='h-full grid gap-3'>
                                <div className='rounded-xl shadow-xl border-orange py-5 px-6 bgFrostIndigo grid items-center no-br'>
                                    <p className='text-purple-100 m-0'><span className= "homepage-heading">Universal<br/></span>Points</p>
                                </div>
                                <div className='rounded-xl shadow-xl border-orange py-5 px-6 bgFrostIndigo grid items-center no-br'>
                                    <p className='text-purple-100 m-0'><span className= "homepage-heading">1K+<br/></span>Merchandise</p>
                                </div>
                            </div>
                            <div className='h-full'>
                                <div className='rounded-xl shadow-xl border-orange p-3 grid place-content-center h-full bgFrostIndigo'>
                                    <img className='mb-10' src="https://res.cloudinary.com/vantagecircle/image/upload/w_100/q_auto/gatsbycms/uploads/2023/10/Rewards-cat-amazon-logo.webp" width="100" height="42" />
                                    <h2 className='text-purple-100 homepage-heading'>1K+</h2>
                                    <p className='text-purple-100'>Amazon products</p>
                                </div>
                            </div>
                        </div>
                        <div className='mx-auto min-h-20 w-full flex place-content-center absolute bottom-0'>
                            <picture>
                                <source className='transform scale-150 xl:mb-10 z-0' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_650/q_auto/gatsbycms/uploads/2023/10/Rewards-cat-global-reach.webp" media="(min-width: 640px)" type="image/webp" alt="vantage-rewards-social-recognition" width="650" />
                                <img className='transform scale-150 xl:mb-10 z-0' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/Rewards-cat-global-reach.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2023/10/Rewards-cat-global-reach.webp" alt="vantage-rewards-social-recognition" width="400" />
                            </picture>
                        </div>
                        <div className='rounded-full p-16 absolute top-1/3 -right-10 lg:hidden' style={{ backgroundColor: 'rgba(200,103,79, 0.5)'}}></div>
                        <div className='rounded-full p-10 absolute top-10 -left-10 lg:hidden' style={{ backgroundColor: 'rgba(129, 136, 227, 0.3)'}}></div>
                    </div>
                </div>
            </section>

            <section className='py-10 xl:py-16 w-full bg-white flex place-content-center relative overflow-hidden'>
                <div className='max-w-7xl mx-auto relative circle-behind'>
                    <h2 className='homepage-heading text-center z-10 relative'>Brands associated with us</h2>
                    {/* <p className='text-center my-6 z-10 relative'>Lorem Ipsum</p> */}
                    <div className='mx-auto mt-16'>
                        <picture>
                            <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/rewards-catalog-brands-1.webp" media="(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="900" />
                            <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/rewards-catalog-brands-1.png" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/10/rewards-catalog-brands-1.webp" alt="Vantage Rewards" width="500" />
                        </picture>
                    </div>
                    <div className='lg:w-72 lg:h-72 xl:w-96 xl:h-96 hidden lg:block absolute -bottom-40 -right-12 xl:-right-20 2xl:-right-32 border rounded-full z-0' style= {{ borderWidth: '20px', background:'transparent', borderColor: '#f5f6fc' }}></div>
                    <div className='lg:w-72 lg:h-72 xl:w-96 xl:h-96 hidden lg:block absolute -top-44 -left-12 xl:-left-20 2xl:-left-28 border rounded-full z-0' style= {{ borderWidth: '20px', background:'transparent', borderColor: '#f5f6fc' }}></div>
                </div>
            </section>
            <section class="w-full flex place-content-center overflow-x-hidden py-10 lg:py-16 overflow-y-hidden px-6 lg:px-0" id="PulseVC">
                <div class="xl:flex lg:w-11/12 xl:max-w-7xl mx-auto bg-indigo-100 overflow-hidden rounded-xl px-0">
                    <div class="card-1 w-full p-7 pb-0 lg:pl-8 xl:p-7 xl:pl-12 md:mb-0 xl:mt-0 xl:w-2/3 2xl:w-2/3 overflow-hidden relative">
                        <h2 class="text-2xl md:text-4xl mx-auto xl:mx-0 text-center xl:text-left text-purple-100 pt-5 mb-8 md:px-12 lg:px-0 lg:w-10/12 xl:w-10/12 2xl:w-9/12">Tell us more about your requirements</h2>
                        {/* <p className='lg:w-11/12 mx-auto xl:mx-0 text-center xl:text-left lg:pl-4 xl:pl-0 text-purple-100 m-0 pb-2'>Highly rated on:</p>
                        <div className='grid grid-cols-3 gap-1 xl:grid-cols-1 md:justify-center items-center'>
                            <div className='flex lg:place-content-center xl:justify-start lg:mt-3 xl:mt-0' data-aos="fade-right" data-aos-duration="650" data-aos-delay="100">
                                <picture>
                                    <source className="my-3" loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/G2-Rating-new-badge.webp" type="image/webp" alt="G2-rating" width="200" />
                                    <img loading="lazy" className='my-3' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/G2-Rating-new-badge.png" alt="G2-rating" width="200" />
                                </picture>
                            </div>
                            <div className='flex lg:place-content-center xl:justify-start lg:mt-3 xl:mt-0' data-aos="fade-right" data-aos-duration="650" data-aos-delay="200">
                                <picture>
                                    <source className="my-3" loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Getapp-Rating-new-badge.webp" type="image/webp" alt="GetApp" width="200" />
                                    <img loading="lazy" className='my-3' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Getapp-Rating-new-badge.png" alt="GetApp" width="200" />
                                </picture>
                            </div>
                            <div className='flex lg:place-content-center xl:justify-start lg:mt-3 xl:mt-0' data-aos="fade-right" data-aos-duration="650" data-aos-delay="300">
                                <picture>
                                    <source className="my-3" loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Capterra-Rating-new-badge.png" type="image/webp" alt="Capterra" width="200" />
                                    <img loading="lazy" className='my-3' src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Capterra-Rating-new-badge.png" alt="Capterra" width="200" />
                                </picture>
                            </div>
                        </div> */}
                        <div className='flex place-content-center xl:absolute right-36 -bottom-0'>
                        <picture>
                            <source className='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_430/q_auto/gatsbycms/uploads/2023/11/CTA-banner-IMG-1.webp" media="(min-width: 640px)" type="image/webp" alt="vantage-rewards-social-recognition" width="380" />
                            <img className='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/11/CTA-banner-IMG-1.webp" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_260/q_auto/gatsbycms/uploads/2023/11/CTA-banner-IMG-1.webp" alt="vantage-rewards-social-recognition" width="250" />
                        </picture>

                        </div>
                    </div>
                    <div class="card-1 rnrForm w-full xl:mt-0 xl:w-5/12 2xl:w-1/3 flex flex-col place-items-center px-6 py-7 lg:py-10 bg-indigo-50" id="form">
                        <h2 class="text-2xl lato text-purple-100 pt-5 mb-8 md:px-12 lg:px-0 text-center">You are now one step away from fostering a dynamic culture of recognition</h2>
                        <div class="w-full flex justify-center items-center lato rewards team">
                            <div class="flex justify-center items-center">
                                <HubspotForm
                                portalId="6153052"
                                formId="50775a1c-3680-401b-b6ff-cc6e54043ed8"
                                onSubmit={() => console.log("Submit!")}
                                onReady={form => console.log("Form ready!")}
                                loading={<div>Loading...</div>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </Layout>
  )
}

export default Rewardscatalog